<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Customer Management')}} > {{ $t('Customers') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Customers') }}</h2>
      </div>

      <div class="filter-wrapper">
        <ItemFilter :placeholder="$t('Search by customer name, email')" default="customerName" :selection="true">
          <el-option :label="$t('Customer Name Search')" value="customerName"></el-option>
          <el-option :label="$t('Email Search')" value="Email Search"></el-option>
        </ItemFilter>
        <a :href="`${apiUrl}/customer/export?token=${token}`"><el-button class="action-button" type="primary">{{ $t('Export Customers') }}</el-button></a>
      </div>

      <div class="inventory-list">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th scope="col">{{ $t('Name') }}</th>
              <th scope="col">{{ $t('Email') }}</th>
              <th scope="col">{{ $t('Phone Number')}}</th>
              <th scope="col">{{ $t('Points')}}</th>
              <th scope="col">{{ $t('Order Count')}}</th>
              <th scope="col">{{ $t('Total Spend') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" v-if="customer.customer_name">
              <td>{{ customer.customer_name }}</td>
              <td>{{ customer.customer_email }}</td>
              <td>{{ customer.customer_phone }}</td>
              <td>{{ customer.total_spend * 0.5 }}</td>
              <td>{{ customer.order_count }}</td>
              <td>HK$ {{ customer.total_spend }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Customer', params: {id: customer.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Customer from '@/lib/customer';

export default {
  name: 'Customers',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadAllCustomers();
  },
  methods:{
    async deleteCustomer(customerId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Customer.deleteCustomer(this.apiUrl, customerId, loginInfo);
        const newCustomers = this.customers.filter(function(item) {
          return item.id !== customerId;
        });
        this.orignalCustomers = newCustomers;
        this.customers = newCustomers;
      }catch(err){
        console.log(err);
      }
    },
    async loadAllCustomers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const customers = await Customer.loadAllCustomers(this.apiUrl, loginInfo);
        this.customers = customers;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    const loginInfo = Common.getLoginInfo();
    const token = loginInfo['token'];

    return {
      token,
      customers: [],
      orignalCustomers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.filter-wrapper{
  display: flex;
  align-items: flex-start;
  .filter{
    flex: 1;
  }
  .action-button{
    position: relative;
    bottom: 10px;
  }
}
</style>
