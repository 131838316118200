<template>
  <div class="report">
    <div class="page-header">
      <h1>{{ $t('Reports') }} > {{$t('Inventory Report') }} </h1>
    </div>
    <div class="page-content">
      <div class="product-list">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th scope="col">{{ $t('Product ID') }}</th>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Received Today') }}</th>
              <th scope="col">{{ $t('Shipping Today') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products">
              <td>{{ product.reference_id }}</td>
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>10</td>
              <td>15</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';
export default {
  name: 'InventoryReport',
  mounted(){
    this.loadProducts();
  },
  data(){
    return {
      products: [],
    }
  },
  methods:{
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}
</style>
