<template>
  <div class="product-form">
    <div class="content-tabs">
      <el-tabs class="form-tabs" v-model="activeName" type="card">
        <el-tab-pane :label="$t('Images')" name="Images">
          <div class="tab-title">
            {{ $t('Upload Product Photos') }}
            {{ $t('(Can upload more than one photo)') }}
          </div>
          <el-upload
          :action="uploadUrl"
          :file-list="productFiles"
          :on-preview="onPreview"
          :on-success="onUploaded"
          :on-remove="onRemove"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-tab-pane>
      <el-tab-pane :label="$t('Product Details')" name="Product Details">
        <div class="tab-message">
          {{ $t('Your shop will display in the language your customer selected. Please fill in the product information in the correct language.')}} <br/><br/>
          {{ $t('If you do not fill in anything in the Chinese section, your customers will only see the English description.')}}
        </div>
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <el-form-item :label="$t('Product Name (English)')" style="max-width: 80%">
            <el-input v-model="form.productNameEn"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Product Name (Chinese)')" style="max-width: 80%">
            <el-input v-model="form.productNameZh"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Product Description (English)')" style="max-width: 80%">
            <el-input
            type="textarea"
            :rows="5"
            v-model="form.productDescriptionEn">
          </el-input>
          </el-form-item>
          <el-form-item :label="$t('Product Description (Chinese)')" style="max-width: 80%">
            <el-input
            type="textarea"
            :rows="5"
            v-model="form.productDescriptionZh">
          </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Categories')" name="Categories">
        <div class="tab-title">
          {{ $t('Categories') }}
        </div>
        <div class="tab-message">
          {{ $t('Product belongs to these Categories (can choose more than 1)')}} <br/><br/>
        </div>
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <el-checkbox-group v-model="form.categories">
            <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_zh"></el-checkbox>
            <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_en"></el-checkbox>
          </el-checkbox-group>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Quantity & Pricing')" name="Quantity & Pricing">
        <el-form ref="form" :model="form" label-width="100px" label-position="left">
          <el-form-item :label="$t('Regular Price')" style="max-width: 30%">
            <el-input v-model="form.regularPrice">
              <template slot="prepend">HK$</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('Quantity')" style="max-width: 30%">
            <el-checkbox v-model="form.unlimitedQuantity">{{ $t('Unlimited Quantity') }}</el-checkbox>
            <el-input v-model="form.quantity" v-if="!form.unlimitedQuantity"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Promotion')" name="Promotion">
        <div class="tab-title">
          {{ $t('Promotion') }}
        </div>
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <div class="promotion-form">
            <div class="row promotion-group">
              <div class="promotions">
                <el-form-item :label="$t('Related Promotion Code')">
                  <el-select v-model="form.relatedPromotions" multiple :placeholder="$t('Please select related promotion')">
                  <el-option
                  v-for="promotion in promotions"
                  :key="promotion.id"
                  :label="promotion.promotion_name"
                  :value="promotion.id">
                </el-option>
              </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Orders')" name="Orders">
        <div class="tab-title">
          {{ $t('Orders') }}
        </div>
        <div class="order-list">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th scope="col">{{ $t('Order No.') }}</th>
              <th scope="col">{{ $t('Date') }}</th>
              <th scope="col">{{ $t('Order Status') }}</th>
              <th scope="col">{{ $t('Total') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders">
              <td>{{ order.reference_id }}</td>
              <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ $t(order.order_status) }}</td>
              <td>HK$ {{ loadOrderAmount(order) }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Order', params: {id: order.id}}">
                    {{ $t('View') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </el-tab-pane>
    </el-tabs>

    <div class="published-checkbox">
      <el-checkbox v-model="form.published">{{ $t('Published') }}</el-checkbox>
    </div>

    <div class="submit-buttons">
      <el-button type="primary" @click="submit"> {{ $t('Submit') }} </el-button>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';
import Promotion from '@/lib/promotion';
import Order from '@/lib/order';

export default {
  name: 'ProductForm',
  data(){
    return {
      form: {
        categories: [],
        productNameZh: '',
        productNameEn: '',
        productDescriptionZh: '',
        productDescriptionEn: '',
        published: true,
        productImages: [],
        unlimitedQuantity: false,
        quantity: '',
        regularPrice: '',
        relatedPromotions: '',
      },
      uploadUrl: '',
      productFiles: [],
      categories: [],
      activeName: 'Images',
      promotions: [],
      orders: [], 
    }
  },
  mounted(){
    this.uploadUrl = `${this.apiUrl}/product/file`;
    this.loadCategories();
    this.loadPromotions();
    this.loadOrders();
  },
  methods:{
    submit(){
      this.$emit('action', this.form);
    },
    onPreview(file){
      window.open(file.url, "_blank");
    },
    onRemove(file){
      this.form.productImages = this.form.productImages.filter(function(image){
         return file.url !== image;
      });
    },
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    loadOrderAmount(order){
      const relatedProducts = JSON.parse(order.related_product);
      const productInfos = order.product_infos;
      const totalAmount = this.loadItemAmount(productInfos, relatedProducts);
      return totalAmount;
    },
    loadItemAmount(productInfos, relatedProducts){
      let totalAmount = 0;
      for(let i = 0; i < relatedProducts.length; i++ ){
        const currentProduct = relatedProducts[i];
        const productInfo = productInfos.find(function(item){
          return currentProduct.id === item.id;
        });
        const productPrice = productInfo['product_regular_price'];
        const productAmount = productPrice * currentProduct.quantity;
        totalAmount += productAmount;
      }
      return totalAmount;
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.productImages.push(fileUrl);
    },
    getCategoriesById(categorieIds){
      const categories = this.categories.filter(function(categorie){
        if(categorieIds.includes(categorie.id)){
          return true;
        }
      });
      const categorieNames = [];
      for(let i = 0; i < categories.length; i++){
        if(this.langcode === 'zh'){
          categorieNames.push(categories[i].categorie_name_zh);
        }else{
          categorieNames.push(categories[i].categorie_name_en);
        }
      }
      return categorieNames;
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Product.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
    async loadPromotions(){
      const loginInfo = Common.getLoginInfo();
      try{
        const promotions = await Promotion.loadAllPromotions(this.apiUrl, loginInfo);
        this.promotions = promotions;
      }catch(err){
        console.log(err);
      }
    },
    async loadOrders(){
      const loginInfo = Common.getLoginInfo();
      try{
        const orders = await Order.loadAllOrders(this.apiUrl, loginInfo);
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
  },
  watch: {
    'currentProduct': async function(){
      const form = {
        categories: [],
        productNameZh: this.currentProduct.product_name_zh,
        productNameEn: this.currentProduct.product_name_en,
        productDescriptionZh: this.currentProduct.product_description_zh,
        productDescriptionEn: this.currentProduct.product_description_en,
        published: this.currentProduct.product_published,
        productImages: [],
        unlimitedQuantity: false,
        quantity: this.currentProduct.product_quantity,
        regularPrice: this.currentProduct.product_regular_price,
        variations: [],
      };

      if(this.currentProduct.product_published == 1){
        form.published = true;
      }else{
        form.published = false;
      }

      if(!this.currentProduct.product_quantity){
        form.unlimitedQuantity = true;
      }

      if(this.currentProduct.had_variation == 1 && this.currentProduct.product_variations){
        form.hadVariations = true;
        const variations = JSON.parse(this.currentProduct.product_variations);
        for(let i = 0; i < variations.length; i++){
          const variation = variations[i];
          const variationInfo = {
            'nameEn': variation.variation_name_en,
            'nameZh': variation.variation_name_zh,
            'options': [],
          };
          for(let a = 0; a < variation.options.length; a++){
            const option =  variation.options[a];
            const optionInfo = {
              'optionNameEn': option.option_name_en,
              'optionNameZh': option.option_name_zh,
              'optionQuantity': option.option_quantity,
            }
            if(option.option_quantity){
              optionInfo.unlimitedQuantity = false;
            }else{
              optionInfo.unlimitedQuantity = true;
            }
            this.optionsList.push(optionInfo);
            variationInfo.options.push(optionInfo);
          }
          form.variations.push(variationInfo);
        }
      }else{
        form.hadVariations = false;
      }
      //Load Categories Name
      form.categories = this.getCategoriesById(this.currentProduct.product_categories);

      //Load proudct image
      const productImages = JSON.parse(this.currentProduct.product_images);
      for(let i = 0; i < productImages.length; i++){
        const imageUrl = productImages[i];
        if(imageUrl){
          const imageFileName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
          const imageFile = {
            name: imageFileName,
            url: imageUrl,
          };
          form.productImages.push(imageUrl);
          this.productFiles.push(imageFile);
        }
      }
      this.form = form;
    },
    'langcode': function(){
      this.form.categories = this.getCategoriesById(this.currentProduct.product_categories);
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
    currentProduct: state => state.product.currentProduct,
  }),
}
</script>

<style lang="scss">
.form-tabs{
  .el-tabs__content{
    display: block !important;
    padding-left: 24px;
  }
  .tab-title{
    padding-bottom: 10px;
    margin-bottom: 15px;
    color: #008EDD;
  }
  .tab-message{
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 0.9em;
  }
}
.variation-form{
  margin-top: 20px;
  margin-bottom: 20px;

  .variation-group{
    padding-left: 15px;
    margin-bottom: 20px;

    .el-input{
      margin-right: 20px;
    }
    .tag-input{
      width: 210px;
    }
    .tag-input::placeholder{
      color: #ddd;
    }
    .variation-option{
      margin-right: 20px;
    }
  }
}

.promotion-form{
  padding-left: 20px;
}

.variations-list{
  margin-top: 30px;
}
.add-button{
  margin-bottom: 20px;
}

.published-checkbox{
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.submit-buttons{
  text-align: right;
}
</style>
