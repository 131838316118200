<template>
  <div class="payments">
    <el-dialog :title="$t('Create Payment Methods')" :visible.sync="displayCreateForm" width="60%">
      <PromotionForm actionType="create" @action="createPromotion" />
    </el-dialog>
    <el-dialog :title="$t('Update Payment Methods')" :visible.sync="displayUpdateForm" width="60%">
      <PromotionForm actionType="update" @action="updatePromotion"/>
    </el-dialog>

    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('Payment Methods')}} </h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Payment Methods') }}</h2>
        <el-button class="action-button" type="primary" @click="showCreateForm"> {{ $t('Create Payment Method') }}</el-button>
      </div>

      <div class="payments-methods-list">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th scope="col">{{ $t('Payment Method Name') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Direct Bank Transfer</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">{{ $t('Edit') }}</a>
                  <a class="action-link danger" href="#">{{ $t('Disable') }}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Credit Card</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">{{ $t('Edit') }}</a>
                  <a class="action-link danger" href="#">{{ $t('Disable') }}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>FPS</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">{{ $t('Edit') }}</a>
                  <a class="action-link danger" href="#">{{ $t('Disable') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ItemFilter from '@/components/Filter.vue';
import PromotionForm from '@/components/promotion/PromotionForm.vue';
import Common from '@/lib/common';
import Promotion from '@/lib/promotion';

export default {
  name: 'PaymentMethods',
  components: {
    ItemFilter,
    PromotionForm,
  },
  mounted(){
    this.loadPaymentMethods();
  },
  methods: {
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    async createPromotion(form){
      try{
        const promotionInfo = Object.assign({}, form);
        promotionInfo.promotionDate[0] = dayjs(promotionInfo.promotionDate[0]).unix();
        promotionInfo.promotionDate[1] = dayjs(promotionInfo.promotionDate[1]).unix();

        const loginInfo = Common.getLoginInfo();
        const promotion = await Promotion.createPromotion(this.apiUrl, promotionInfo, loginInfo);
        this.displayCreateForm = false;
      }catch(err){
        console.log(err);
      }
    },
    async updatePromotion(form, promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotionInfo = Object.assign({}, form);
        promotionInfo.promotionDate[0] = dayjs(promotionInfo.promotionDate[0]).unix();
        promotionInfo.promotionDate[1] = dayjs(promotionInfo.promotionDate[1]).unix();
        const promotion = await Promotion.updatePromotion(this.apiUrl, promotionId, form, loginInfo);
        this.displayUpdateForm = false;
      }catch(err){
        console.log(err);
      }
    },
    async loadPromotion(promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotion = await Promotion.loadPromotion(this.apiUrl, promotionId, loginInfo);
        this.setCurrentPromotion(promotion);
      }catch(err){
        console.log(err);
      }
    },
    async loadPaymentMethods(){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotions = await Promotion.loadAllPaymentMethods(this.apiUrl, loginInfo);
        this.promotions = promotions;
        this.orignalPaymentMethods = promotions;
      }catch(err){
        console.log(err);
      }
    },
    async deletePromotion(promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Promotion.deletePromotion(this.apiUrl, promotionId, loginInfo);
        const newPaymentMethods = this.promotions.filter(function(item) {
          return item.id !== promotionId;
        });
        this.orignalPaymentMethods = newPaymentMethods;
        this.promotions = newPaymentMethods;
      }catch(err){
        console.log(err);
      }
    },
    showCreateForm(){
      this.displayCreateForm = true;
    },
    showUpdateForm(promotionId){
      this.loadPromotion(promotionId);
      this.displayUpdateForm = true;
    },
    ...mapActions(['setCurrentPromotion']),
  },
  data(){
    return {
      activeName: 'All',
      displayCreateForm: false,
      displayUpdateForm: false,
      promotions: [],
      orignalPaymentMethods: [],
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
