<template>
  <div class="sidemenu">
    <div class="sidebar-menu-btn" @click="controlSidebar">
      <div class="hamburger-lines">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
    </div>
    <div class="logo-sidemenu">
      <div class="sidemenu-title">
        {{ $t('OCTOPUS TRADING') }}
      </div>
    </div>
    <el-menu :default-active="activeIndex" class="sidemenu-menu" background-color="#3E3F3A" text-color="#fff"
      active-text-color="#ffd04b">
      <div class="menu-item-wrapper" v-for="menuItem in menuItems">
        <router-link :to="menuItem.link" :key="menuItem.index" v-if="menuItem.type == 'menu-item'">
          <el-menu-item :index="menuItem.index">
            <i :class="menuItem.icon"></i>
            {{ $t(menuItem.title) }}
          </el-menu-item>
        </router-link>

        <el-submenu :index="menuItem.index" :key="menuItem.index" v-if="menuItem.type == 'submenu'">
          <template slot="title">
            <i :class="menuItem.icon"></i>
            <span slot="title">{{ $t(menuItem.title) }}</span>
          </template>
          <router-link :to="subItem.link" :index="subItem.index" :key="subItem.index"
            v-for="subItem in menuItem.submenu">
            <el-menu-item :index="subItem.index">
              {{ $t(subItem.title) }}
            </el-menu-item>
          </router-link>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Sidemenu',
  data() {
    return {
      activeIndex: '1',
      menuItems: [
        {
          'index': '1',
          'type': 'menu-item',
          'icon': 'el-icon-menu',
          'title': 'Dashboard',
          'link': '/dashboard',
        },
        {
          'index': '2',
          'type': 'submenu',
          'icon': 'el-icon-s-order',
          'title': 'Order Management',
          'submenu': [
            {
              'index': '2-1',
              'title': 'Orders',
              'link': '/orders',
            },
          ],
        },
        {
          'index': '3',
          'type': 'submenu',
          'icon': 'el-icon-s-goods',
          'title': 'Product Management',
          'submenu': [
            {
              'index': '3-1',
              'title': 'Products',
              'link': '/products',
            },
            {
              'index': '3-2',
              'title': 'Categories',
              'link': '/categories',
            },
          ],
        },
        {
          'index': '5',
          'type': 'submenu',
          'icon': 'el-icon-s-shop',
          'title': 'Inventory Management',
          'submenu': [
            {
              'index': '5-1',
              'title': 'Inventory',
              'link': '/inventory',
            },
          ],
        },
        {
          'index': '6',
          'type': 'submenu',
          'icon': 'el-icon-user-solid',
          'title': 'Customers Management',
          'submenu': [
            {
              'index': '6-1',
              'title': 'Customers',
              'link': '/customers',
            },
          ],
        },
        {
          'index': '7',
          'type': 'submenu',
          'icon': 'el-icon-s-ticket',
          'title': 'Promotion Management',
          'submenu': [
            {
              'index': '7-1',
              'title': 'Promotions',
              'link': '/promotions',
            },
          ],
        },
        {
          'index': '8',
          'type': 'submenu',
          'icon': 'el-icon-news',
          'title': 'Comment Management',
          'submenu': [
            {
              'index': '8-1',
              'title': 'Comments',
              'link': '/comments',
            },
          ],
        },
        {
          'index': '10',
          'type': 'menu-item',
          'icon': 'el-icon-s-open',
          'title': 'Content Management',
          'link': '/content',
        },
        {
          'index': '11',
          'type': 'submenu',
          'icon': 'el-icon-mobile-phone',
          'title': 'Income Management',
          'submenu': [
            {
              'index': '11-1',
              'title': 'Clients',
              'link': '/clients',
            },
            {
              'index': '11-2',
              'title': 'Invoice / Receipt PDF',
              'link': '/documents',
            },
          ],
        },
        {
          'index': '12',
          'type': 'submenu',
          'icon': 'el-icon-date',
          'title': 'Service Booking',
          'submenu': [
            {
              'index': '12-1',
              'title': 'Services',
              'link': '/services',
            },
            {
              'index': '12-2',
              'title': 'Service Orders',
              'link': '/services/orders',
            }
          ],
        },
        {
          'index': '13',
          'type': 'submenu',
          'icon': 'el-icon-s-data',
          'title': 'Reports',
          'submenu': [
            {
              'index': '13-1',
              'title': 'Yearly/ Monthly/ Daily Report',
              'link': '/report/date',
            },
            {
              'index': '13-2',
              'title': 'Most Popular Product Report',
              'link': '/report/popular',
            },
            {
              'index': '13-3',
              'title': 'Report per product',
              'link': '/report/product',
            },
            {
              'index': '13-4',
              'title': 'Inventory Report',
              'link': '/report/inventory',
            }
          ],
        },
        {
          'index': '14',
          'type': 'submenu',
          'icon': 'el-icon-s-tools',
          'title': 'Settings',
          'submenu': [
            {
              'index': '14-1',
              'title': 'Shop Settings',
              'link': '/settings/shop',
            },
            {
              'index': '14-2',
              'title': 'Account Settings',
              'link': '/settings/account',
            },
            {
              'index': '14-3',
              'title': 'Backup',
              'link': '/settings/backup',
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.checkActive();
  },
  methods: {
    controlSidebar() {
      const body = document.querySelector('body');
      const sidebar = document.querySelector('.sidemenu');
      const mainContent = document.querySelector('.right-content');
      if (!this.displaySidebar) {
        this.showSidebar();
        body.classList.add('display-sidemenu');
        sidebar.classList.add('display-mobile');
        mainContent.classList.add('openmenu');
      } else {
        this.hiddenSidebar();
        body.classList.remove('display-sidemenu');
        sidebar.classList.remove('display-mobile');
        mainContent.classList.remove('openmenu');
      }
    },
    //Check current active menu item
    checkActive() {
      let activeIndex = '';
      const currentPath = window.location.pathname;
      for (let i = 0; i < this.menuItems.length; i++) {
        const menuItem = this.menuItems[i];
        //If the menu item do not had submenu
        if (menuItem.type === 'menu-item') {
          if (menuItem.link === currentPath) {
            activeIndex = menuItem.index;
            return;
          }
        } else {
          //If the menu item had submenu
          menuItem['submenu'].forEach(function (item) {
            if (item.link === currentPath) {
              activeIndex = item.index;
              return;
            }
          });
        }
      }
      this.activeIndex = activeIndex;
    },
    ...mapActions(['showSidebar', 'hiddenSidebar']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    displaySidebar: state => state.displaySidebar,
  }),
}
</script>

<style scoped lang="scss">
.logo-sidemenu {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 25px;
  color: white;
  display: flex;
  align-items: center;

  img {
    width: 30%;
  }
}

.sidemenu {
  width: 270px;
  background: #3E3F3A;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;

  @media screen and (max-width: 992px) {
    display: none;
  }

  a {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }

  .sidemenu-menu {
    border-right: 0;
  }

  &.display-mobile {
    display: block;
    @media screen and (max-width: 320px) {
      width: 200px;
    }
  }
}

.sidebar-menu-btn{
  margin-left: 0;
  margin-top: 20px;
  margin-left: 25px;
}
</style>
