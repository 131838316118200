<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Income Management')}} > {{ $t('Clients') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Clients') }}</h2>
          <router-link :to="{ name: 'CreateClient' }">
          <el-button class="action-button" type="primary">{{ $t('Add Client') }}</el-button>
        </router-link>
        </div>

        <div class="inventory-list">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th scope="col">{{ $t('Name') }}</th>
                <th scope="col">{{ $t('Email') }}</th>
                <th scope="col">{{ $t('Phone Number')}}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="client in clients" v-if="client.client_name">
                <td>{{ client.client_name }}</td>
                <td>{{ client.client_email }}</td>
                <td>{{ client.client_phone }}</td>
                <td>
                  <div class="action-links">
                    <router-link class="action-link" :to="{name:'Client', params: {id: client.id}}">
                      {{ $t('Edit') }}
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import Client from '@/lib/client';
  
  export default {
    name: 'Clients',
    mounted(){
      this.loadAllClients();
    },
    methods:{
      async deleteClient(clientId){
        try{
          const loginInfo = Common.getLoginInfo();
          await Client.deleteClient(this.apiUrl, clientId, loginInfo);
          const newClients = this.clients.filter(function(item) {
            return item.id !== clientId;
          });
          this.orignalClients = newClients;
          this.clients = newClients;
        }catch(err){
          console.log(err);
        }
      },
      async loadAllClients(){
        try{
          const loginInfo = Common.getLoginInfo();
          const clients = await Client.loadAllClients(this.apiUrl, loginInfo);
          this.clients = clients;
        }catch(err){
          console.log(err);
        }
      },
    },
    data(){
      const loginInfo = Common.getLoginInfo();
      const token = loginInfo['token'];
  
      return {
        token,
        clients: [],
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  .filter-wrapper{
    display: flex;
    align-items: flex-start;
    .filter{
      flex: 1;
    }
    .action-button{
      position: relative;
      bottom: 10px;
    }
  }
  </style>
  