<template>
    <div class="orders">
      <div class="page-header">
        <h1>{{ $t('Service Order Management') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Service Orders') }}</h2>
          <router-link :to="{ name: 'CreateServiceOrder' }">
            <el-button class="action-button" type="primary">{{ $t('Create Order') }}</el-button>
          </router-link>
        </div>
        <div class="content-tabs">
          <el-tabs v-model="activeName" @tab-click="onTabChange" type="card">
            <el-tab-pane :label="$t('All')" name="All">{{ $t('All') }}</el-tab-pane>
            <el-tab-pane :label="$t('Unpaid')" name="Unpaid">{{ $t('Unpaid') }}</el-tab-pane>
            <el-tab-pane :label="$t('Completed')" name="Completed">{{ $t('Completed') }}</el-tab-pane>
            <el-tab-pane :label="$t('Cancelled')" name="Cancelled">{{ $t('Cancelled') }}</el-tab-pane>
          </el-tabs>
        </div>
        <ItemFilter :placeholder="$t('Search by order number')" default="orderNum" @search="searchItems">
          <el-option :label="$t('Order No. Search')" value="orderNum"></el-option>
        </ItemFilter>
        <div class="order-list">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th scope="col">{{ $t('Order No.') }}</th>
                <th scope="col">{{ $t('Date') }}</th>
                <th scope="col">{{ $t('Order Status') }}</th>
                <th scope="col">{{ $t('Total') }}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders">
                <td>{{ order.reference_id }}</td>
                <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ $t(order.order_status) }}</td>
                <td>HK$ {{ loadOrderAmount(order) }}</td>
                <td>
                  <div class="action-links">
                    <router-link class="action-link" :to="{name:'ServiceOrder', params: {id: order.id}}">
                      {{ $t('Edit') }}
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import ServiceOrder from '@/lib/serviceOrder';
  import Customer from '@/lib/customer';
  import ItemFilter from '@/components/Filter.vue';
  
  export default {
    name: 'ServiceOrders',
    components: {
      ItemFilter,
    },
    mounted(){
      this.loadOrders();
    },
    data(){
      return {
        activeName: 'All',
        orders: [],
        searchOrders: null, //Keep the tab result
        orignalOrders: [],
      }
    },
    methods:{
      searchItems(form){
        const searchField = {};
        let newItems = [];
        switch(form.filterTarget){
          case 'orderNum':
          searchField['reference_id'] = form.filterValue;
          newItems = Common.filterItems(searchField, this.orignalOrders);
          this.searchOrders = newItems;
          this.orders = newItems;
          break;
        }
      },
      formatDateTime(timestamp, format){
        return Common.formatDateTime(timestamp, format);
      },
      loadOrderAmount(order){
        const relatedServices = JSON.parse(order.related_service);
        const serviceInfos = order.service_infos;
        const totalAmount = this.loadItemAmount(serviceInfos, relatedServices);
        return totalAmount;
      },
      loadItemAmount(serviceInfos, relatedServices){
        let totalAmount = 0;
        for(let i = 0; i < relatedServices.length; i++ ){
          const currentService = relatedServices[i];
          const serviceInfo = serviceInfos.find(function(item){
            return currentService === item.id;
          });
          const servicePrice = Number(serviceInfo['service_price']);
          totalAmount += servicePrice;
        }
        return totalAmount;
      },
      async loadOrders(){
        try{
          const loginInfo = Common.getLoginInfo();
          const orders = await ServiceOrder.loadAllOrders(this.apiUrl, loginInfo);
          this.orignalOrders = orders;
          this.orders = orders;
        }catch(err){
          console.log(err);
        }
      },
      async deleteOrder(orderId){
        try{
          const loginInfo = Common.getLoginInfo();
          await ServiceOrder.deleteOrder(this.apiUrl, orderId, loginInfo);
          const newOrders = this.orders.filter(function(item) {
            return item.id !== orderId;
          });
          this.orignalOrders = newOrders;
          this.orders = newOrders;
        }catch(err){
          console.log(err);
        }
      },
      async onTabChange(){
        const activeName = this.activeName;
        if(activeName !== 'All'){
          this.loadLimitOrders(activeName);
        }else{
          this.orders = this.orignalOrders;
        }
      },
      async loadLimitOrders(type){
        const searchFields = {
          'order_status': '',
        }
        if(type === 'Unpaid'){
          if(this.searchOrders){
            const newItems = this.searchOrders.filter(function(order){
              return order['order_status'] == 'Unpaid';
            });
            this.orders = newItems;
          }else{
            const newItems = this.orignalOrders.filter(function(order){
              return order['order_status'] == 'Unpaid';
            });
            this.orders = newItems;
          }
        }
        if(type === 'Unfulfilled'){
          if(this.searchOrders){
            const newItems = this.searchOrders.filter(function(order){
              return order['order_status'] == 'Unfulfilled';
            });
            this.orders = newItems;
          }else{
            const newItems = this.orignalOrders.filter(function(order){
              return order['order_status'] == 'Unfulfilled';
            });
            this.orders = newItems;
          }
        }
        if(type === 'Completed'){
          if(this.searchOrders){
            const newItems = this.searchOrders.filter(function(order){
              return order['order_status'] == 'Completed';
            });
            this.orders = newItems;
          }else{
            const newItems = this.orignalOrders.filter(function(order){
              return order['order_status'] == 'Completed';
            });
            this.orders = newItems;
          }
        }
        if(type === 'Cancelled'){
          if(this.searchOrders){
            const newItems = this.searchOrders.filter(function(order){
              return order['order_status'] == 'Cancelled';
            });
            this.orders = newItems;
          }else{
            const newItems = this.orignalOrders.filter(function(order){
              return order['order_status'] == 'Cancelled';
            });
            this.orders = newItems;
          }
        }
        if(type === 'All'){
          if(this.searchOrders){
            this.orders = searchOrders;
          }else{
            this.orders = orignalOrders;
          }
        }
      },
    },
    computed: mapState({
      langcode: state => state.langcode,
      apiUrl: state => state.apiUrl,
    }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  